import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';

import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class CompanyService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getCompanyInfo = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/company`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getCompanyAddressBookEntries = ({ query, senderLinkType, senderId, sortBy, pageNo, pageSize }) => {
    const requestPayload = {
      sender_link_type: senderLinkType || undefined,
      sender_id: window.IS_BOOKING_APP ? null : senderId,
      sort_by: sortBy || 'external_id',
      query,
      page: pageNo || 1,
      page_size: pageSize || 5,
    };

    const apiPath = window.IS_BOOKING_APP ? 'sender/address_items' : 'dispatcher/address_items';

    return this.authService
      .get(this.getUmbrellaApiUrl(apiPath), requestPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getTimeZone = ({ lat, lng }) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4('public/location/timezone'), { lat, lng })
      .then((response) => {
        const result = this.extractData(response);
        return result?.['data'] ?? null;
      })
      .catch((error) => this.handleError(error));
  };

  getCompanyTags = ({ q, type, page = 1, pageSize = 5000 } = {}) => {
    const requestPayload = {
      q: q ? q : undefined,
      type,
      page,
      page_size: pageSize,
    };

    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/company/tags'), requestPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getTaskTypes = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('public/task_type_mappings'), { company_slug: getSlugFromUrl() })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : [];
      })
      .catch((error) => this.handleError(error));
  };

  getPublicOrderCompanyInfo = () => {
    const requestPayload = {
      company_slug: getSlugFromUrl(),
    };

    return this.authService
      .get(this.getUmbrellaApiUrl('public/orders/company_info'), requestPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getCompanyNonOperationalZones = () => {
    const requestPayload = {
      company_slug: getSlugFromUrl(),
    };

    return this.authService
      .get(this.getUmbrellaApiUrl('public/orders/non_operational_zones'), requestPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const companyService = new CompanyService({ authService });
