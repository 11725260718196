// Important note: terms `group` and `leg` are indicate the same thing
import { t } from 'i18next';
import { cloneDeep } from 'lodash-es';
import moment from 'moment-timezone';

import { FORMAT_DATE_TIME_NORMAL } from '@yojee/helpers/constants';
import {
  correctErrorMessageWithTemplateFieldsSchema,
  ERROR_CODES,
  getErrorsInClearStructure,
} from '@yojee/helpers/errorHelper';
import { getStepUniqueKey } from '@yojee/ui/new-order-booking/components/OrderForm/OrderActions/utils';

export const AddressType = {
  book: 'Address book',
  google: 'Google',
};

export const transformAddressBookDataToOptionData = (item) => {
  return {
    id: item.id,
    type: AddressType.book,
    value: item,
    name: item.address1,
    optionLabel: `${item.external_id || item.id} - ${item.address1}`,
    ...item.location,
  };
};

function convertDateTimeFromUTCToTimezone({ utcDateStr, timezone, type = 'date' }) {
  if (!utcDateStr) return undefined;

  let localDate;

  // If we don't have timezone, we use browser time
  if (!timezone) {
    localDate = moment.utc(utcDateStr).local();
  } else {
    const dateStr = moment(utcDateStr).utc().format(FORMAT_DATE_TIME_NORMAL);
    const dateInTimezone = moment.utc(dateStr).tz(timezone);
    localDate = moment(dateInTimezone.format(FORMAT_DATE_TIME_NORMAL));
  }

  if (type === 'date') {
    return localDate.format();
  }
  return localDate;
}

/**
 * Journey is list of step
 * @param orderItemSteps
 * @returns {*}
 */
function getItemIdJourneyMap(orderItemSteps = []) {
  const itemIdJourney = orderItemSteps.reduce((acc, orderItemStep) => {
    const { order_item_id } = orderItemStep;
    acc[order_item_id] = [...(acc[order_item_id] || []), orderItemStep];

    return acc;
  }, {});

  for (const itemId in itemIdJourney) {
    const journey = itemIdJourney[itemId];
    itemIdJourney[itemId] = journey.sort(
      (orderItemStep1, orderItemStep2) => orderItemStep1.step_sequence - orderItemStep2.step_sequence
    );
  }

  return itemIdJourney;
}

/**
 * Uncompleted item will place first
 * @param item1
 * @param item2
 * @returns {number}
 */
function sortItemByStatus(item1, item2) {
  if (item1?.status === 'completed') {
    return -1;
  } else if (item2?.status === 'completed') {
    return -1;
  } else {
    return 1;
  }
}

/**
 * Example return data:
 * [
 *  {
 *    itemIds: [1,2,3],
 *    itemSteps: [
 *      {
 *        id: 1,
 *        order_step_id: 11,
 *        ...
 *      }
 *    ]
 *  }
 * ]
 * @param itemIdJourneyMap
 * @param itemIdItemDataMap
 * @returns {{itemSteps: *, itemIds: *}[]}
 */
function groupItemIdsByJourney(itemIdJourneyMap = {}, itemIdItemDataMap = {}) {
  const getJourneyKey = (journey) => journey.map((orderItemStep) => getStepUniqueKey(orderItemStep)).join(',');

  const journeyKeyItemIdsMap = Object.keys(itemIdJourneyMap).reduce((acc, itemId) => {
    const journeyKey = getJourneyKey(itemIdJourneyMap[itemId]);
    acc[journeyKey] = [...(acc[journeyKey] || []), parseInt(itemId)];

    return acc;
  }, {});

  return Object.keys(journeyKeyItemIdsMap).map((journeyKey) => {
    const itemIds = journeyKeyItemIdsMap[journeyKey];

    return {
      itemIds: journeyKeyItemIdsMap[journeyKey],
      itemSteps: itemIdJourneyMap[itemIds[0]],
    };
  });
}

function getALlStepGroupNumber(itemSteps = []) {
  const stepGroups = new Set();
  itemSteps?.forEach((itemStep) => stepGroups.add(itemStep.step_group));
  return [...stepGroups].sort();
}

function parseLegs({ itemSteps, idOrderStepMap }) {
  const stepGroupNumbers = getALlStepGroupNumber(itemSteps);
  return stepGroupNumbers.map((stepGroupNumber) => {
    return (itemSteps || [])
      .filter((orderItemStep) => orderItemStep.step_group === stepGroupNumber)
      .sort((orderItemStep1, orderItemStep2) => orderItemStep1.step_sequence - orderItemStep2.step_sequence)
      .map((orderItemStep) => ({
        ...orderItemStep,
        ...idOrderStepMap[orderItemStep.order_step_id],
      }))
      .map((step, index) => ({
        ...step,
        from_time: convertDateTimeFromUTCToTimezone({ utcDateStr: step.from_time, timezone: step.timezone }),
        to_time: convertDateTimeFromUTCToTimezone({ utcDateStr: step.to_time, timezone: step.timezone }),
        from_time_time: convertDateTimeFromUTCToTimezone({
          utcDateStr: step.from_time,
          timezone: step.timezone,
          type: 'time',
        }),
        to_time_time: convertDateTimeFromUTCToTimezone({
          utcDateStr: step.to_time,
          timezone: step.timezone,
          type: 'time',
        }),
        mapPosition: step?.location,
        is_empty: step.is_empty,
      }));
  });
}

function moveBookingInfoSectionContainPriorityItemIDToTop({ bookingInfoSections = [], priorityItemID }) {
  const isBookingInfoSectionContainItem = (bookingInfoSection, itemId) =>
    !!bookingInfoSection.itemIds.find((i) => i === itemId);
  const newBookingInfoSections = bookingInfoSections.filter(
    (bookingInfoSection) => !isBookingInfoSectionContainItem(bookingInfoSection, priorityItemID)
  );
  const bookingInfoSectionContainPriorityItemId = bookingInfoSections.find((bookingInfoSection) =>
    isBookingInfoSectionContainItem(bookingInfoSection, priorityItemID)
  );

  if (bookingInfoSectionContainPriorityItemId) {
    newBookingInfoSections.unshift(bookingInfoSectionContainPriorityItemId);
  }

  return newBookingInfoSections;
}

function moveBookingInfoSectionsContainUncompletedItemToTop({ bookingInfoSections = [], itemIdItemDataMap }) {
  return [...bookingInfoSections].sort((bookingInfoSection1, bookingInfoSection2) => {
    const firstItemOfSection1 = itemIdItemDataMap[bookingInfoSection1.itemIds[0]];
    const firstItemOfSection2 = itemIdItemDataMap[bookingInfoSection2.itemIds[0]];
    return sortItemByStatus(firstItemOfSection1, firstItemOfSection2);
  });
}

function sortBookingInfoSections({ bookingInfoSections = [], itemIdItemDataMap, priorityItemID }) {
  const sortedList = moveBookingInfoSectionsContainUncompletedItemToTop({ bookingInfoSections, itemIdItemDataMap });
  if (priorityItemID) {
    return moveBookingInfoSectionContainPriorityItemIDToTop({ bookingInfoSections: sortedList, priorityItemID });
  }

  return sortedList;
}

/**
 * Move priority item to top
 * @param items
 * @param priorityItemID
 * @returns {*[]}
 */
function movePriorityItemToTop({ items = [], priorityItemID }) {
  if (priorityItemID) {
    const newItems = items.filter((item) => item.id !== priorityItemID);
    const priorityItem = items.find((item) => item.id === priorityItemID);

    if (priorityItem) newItems.unshift(priorityItem);
    return newItems;
  }

  return items;
}

/**
 * Move priority item to top
 * @param items
 * @param priorityItemID
 * @returns {*[]}
 */
function sortItems({ items = [], priorityItemID }) {
  const sortedItems = items.sort(sortItemByStatus);
  if (priorityItemID) {
    return movePriorityItemToTop({ items: sortedItems, priorityItemID });
  }

  return sortedItems;
}

function buildMasterData({ order_steps, order_items }) {
  const idOrderStepMap = order_steps.reduce((acc, orderStep) => {
    acc[orderStep.id] = orderStep;
    return acc;
  }, {});

  const itemIdItemDataMap = order_items.reduce((acc, orderItem) => {
    acc[orderItem.id] = orderItem;
    return acc;
  }, {});

  return {
    idOrderStepMap,
    itemIdItemDataMap,
  };
}

function parseBookingInfoSections({ order_steps, order_item_steps, order_items }) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const priorityItemID = parseInt(urlSearchParams.get('selected_item_id') || 0);
  const { itemIdItemDataMap, idOrderStepMap } = buildMasterData({ order_steps, order_items });

  const itemIdJourneyMap = getItemIdJourneyMap(order_item_steps);
  let bookingInfoSections = groupItemIdsByJourney(itemIdJourneyMap, itemIdItemDataMap);

  bookingInfoSections = sortBookingInfoSections({ bookingInfoSections, itemIdItemDataMap, priorityItemID });

  return bookingInfoSections.map(({ itemIds, itemSteps }) => {
    const itemIdSet = new Set(itemIds);

    let items = order_items.filter((item) => itemIdSet.has(item.id));
    items = sortItems({ items, priorityItemID });
    const itemDetails = items.map(({ item_container, dangerous_goods_info, ...remain }) => ({
      item: remain,
      item_container,
      dangerous_goods_info,
    }));

    const legs = parseLegs({ itemSteps, idOrderStepMap });

    return {
      itemDetails,
      legs,
    };
  });
}

const putTimeConflictWarningFlag = (order_item_steps) => {
  let startsOfNewLeg = true;
  let currLegIndex = 0;

  return order_item_steps
    .reduce((acc, ois) => {
      let currStep;

      // Is this item the start of a new leg?
      if (ois.step_group > currLegIndex) {
        startsOfNewLeg = true;
        currLegIndex = ois.step_group;
      }

      if (startsOfNewLeg && (ois.time_conflict_with_prev || ois.time_overlap_with_prev)) {
        currStep = {
          ...ois,
          display_time_conflict_warning: true,
        };

        startsOfNewLeg = false;
      } else if (startsOfNewLeg) {
        currStep = {
          ...ois,
          display_time_conflict_warning: false,
        };

        startsOfNewLeg = false;
      } else {
        currStep = {
          ...ois,
          display_time_conflict_warning: acc[0].display_time_conflict_warning,
        };
      }

      return [currStep, ...acc];
    }, [])
    .reverse();
};

export const fromOrderDataToOrderFormModels = (orderData = {}) => {
  const {
    order_info = {},
    order_steps = [],
    order_item_steps = [],
    order_items = [],
    // TODO: Remove this, packing_mode will move to order_info soon
    order_step_groups = [],
    order_voyage_info,
    upstream_metadata,
  } = orderData;

  const modifiedOrderItemSteps = putTimeConflictWarningFlag(order_item_steps);

  return {
    sender: order_info.sender ?? {},
    order: {
      ...order_info,
      packing_mode: order_step_groups?.[0]?.packing_mode?.toLowerCase(),
    },
    bookingInfoSections: parseBookingInfoSections({
      order_steps,
      order_item_steps: modifiedOrderItemSteps,
      order_items,
    }),
    orderItemSteps: modifiedOrderItemSteps,
    upstreamMetadata: upstream_metadata,
    order_voyage_info,
  };
};

export const fromOrderFieldTemplateDateToOrderFormModels = (orderData = {}) => {
  return fromOrderDataToOrderFormModels(orderData);
};

function getOriginalStep(orderSteps, stepId) {
  return orderSteps?.find((orderStep) => orderStep.id === stepId);
}

export const updateTimeOfStepBaseOnTemplate = ({ originalOrderDetailData, orderFormModel, templateDetailData }) => {
  const isDontHaveTimezoneField = !templateDetailData?.fields_schema?.order_step_timezone?.selected;

  // If template don't have timezone field, we calculate from_time, to_time base on current browser time
  if (isDontHaveTimezoneField) {
    const newOrderFormModel = cloneDeep(orderFormModel);

    newOrderFormModel?.bookingInfoSections?.forEach((bookingInfoSection) => {
      bookingInfoSection?.legs?.forEach((leg) => {
        leg?.forEach((step) => {
          const originalStep = getOriginalStep(originalOrderDetailData?.order_steps, step.order_step_id);

          step.from_time = convertDateTimeFromUTCToTimezone({
            utcDateStr: originalStep.from_time,
            timezone: originalStep.timezone,
          });
          step.to_time = convertDateTimeFromUTCToTimezone({
            utcDateStr: originalStep.to_time,
            timezone: originalStep.timezone,
          });
          step.from_time_time = convertDateTimeFromUTCToTimezone({
            utcDateStr: originalStep.from_time,
            timezone: originalStep.timezone,
            type: 'time',
          });
          step.to_time_time = convertDateTimeFromUTCToTimezone({
            utcDateStr: originalStep.to_time,
            timezone: originalStep.timezone,
            type: 'time',
          });

          step.timezone = originalStep.timezone;
        });
      });
    });

    return newOrderFormModel;
  }

  return orderFormModel;
};

export const fromListErrorObjToErrorStr = (errors, templateFields = {}) => {
  return getErrorsInClearStructure(errors)
    .map((error) => {
      if (error.code === ERROR_CODES.dateInThePast) {
        if (error.metadata?.ref?.includes('order_steps[0]')) {
          return t('Pickup time in the past');
        } else if (error.metadata?.ref?.includes('order_steps[1]')) {
          return t('Drop off time in the past');
        }

        return error.message;
      }

      return correctErrorMessageWithTemplateFieldsSchema(error, templateFields);
    })
    .join('\n');
};
